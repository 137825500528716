export const Routers = {
    newdashboard:"/newdashboard",
    dashboard : "/dashboard",
    departments : "/departments",
    departments_details : "/departments/details",
    chaindepartments : "/chaindepartments",
    chaindepartments_details : "/chaindepartments/details",
    chaindepartments_addnewdepartment : "/chaindepartments/addnewdepartment",
    tags : "/tags",
    brands : "/brands",
    suppliers : "/suppliers",
    products : "/products",
    products_details : "/products/details",
    displayunits : "/displayunits",
    displayunits_details : "/displayunits/details",
    floors : "/floors",
    floors_details : "/floors/details",
    planograms : "/planograms",
    planograms_details : "/planograms/details",
    planograms_displayunit : "/planograms/displayunit",
    hierarchy : "/hierarchy",
    landing : "/landing",
    excelupload : "/excelupload",
    regions : "/regions",
    branches : "/branches",
    users : "/users",
    users_details : "/users/details",
    usergroups : "/usergroups",
    salesLog : "/salesLog",
    newProducts : "/newProducts",
    catelogueImport : "/catelogueImport",
    departmentImplementation : "/departmentImplementation",
    profilesettings : "/profilesettings",
    storeProducts : "/storeProducts",
    manualcompliance : "/manualcompliance",
    manualcompliance_details : "/manualcompliance/details",
    masterplanograms : "/masterplanograms",
    masterplanograms_layoutview : "/masterplanograms/layoutview",
    prodnotifications : "/prodnotifications",
    aristomaps : "/aristomaps",
    newproductlogs : "/newproductlogs",
    tasks : "/tasks",
    tasks_summery : "/tasks/summery",
    questionlist : "/questionlist",
    questionlist_details : "/questionlist/details",
    signin : "/signin",
    resetPassword : "/resetPassword",
    confirmation : "/confirmation",
    newplanograms:"/newplanograms",
    notifications: '/notifications',
    
}

export const REQPages = {
    newdashboard:"newdashboard",
    dashboard : "dashboard",
    departments : "departments",
    // departments_details : "/departments/details",
    chaindepartments : "chaindepartments",
    // chaindepartments_details : "/chaindepartments/details",
    // chaindepartments_addnewdepartment : "/chaindepartments/addnewdepartment",
    tags : "tags",
    brands : "brands",
    suppliers : "suppliers",
    products : "products",
    // products_details : "/products/details",
    displayunits : "displayunits",
    // displayunits_details : "/displayunits/details",
    floors : "floors",
    // floors_details : "/floors/details",
    planograms : "planogram",
    // planograms_details : "/planograms/details",
    // planograms_displayunit : "/planograms/displayunit",
    hierarchy : "hierarchy",
    // landing : "/landing",
    excelupload : "excelupload",
    regions : "regions",
    branches : "branches",
    users : "users",
    // users_details : "/users/details",
    usergroups : "usergroups",
    salesLog : "salesLog",
    newProducts : "newProducts",
    catelogueImport : "catelogueImport",
    departmentImplementation : "departmentImplementation",
    profilesettings : "profilesettings",
    storeProducts : "storeProducts",
    manualcompliance : "manualcomp",
    // manualcompliance_details : "/manualcompliance/details",
    masterplanograms : "masterPlanogram",
    // masterplanograms_layoutview : "/masterplanograms/layoutview",
    prodnotifications : "prodnotifications",
    aristomaps : "aristomaps",
    tasks : "taskfeed",
    
}