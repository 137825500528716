import { SELECT_ROW_SET } from '../constants/newPlanogramsTypes';

const INITIAL_STATE = { selectRow: 0};
 
const newPlanogramReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SELECT_ROW_SET:
        return {
          ...state,
          selectRow: action.payload
        };
      default:
        return state;
    }
  };
 
export default newPlanogramReducer;