import { SELECT_LANGUAGE } from '../constants/themeTypes';

const INITIAL_STATE = { selectLanguage: 'ltr'};
 
const languageTypeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SELECT_LANGUAGE:
        return {
          ...state,
          selectLanguage: action.payload
        };
      default:
        return state;
    }
};
 
export default languageTypeReducer;