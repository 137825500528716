import { SET_VIEW_FIELD } from '../constants/newPlanogramsTypes';

const INITIAL_STATE = { selectViewFields: null};
 
const navigateFieldReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SET_VIEW_FIELD:
        return {
          ...state,
          selectViewFields: action.payload
        };
      default:
        return state;
    }
  };
 
export default navigateFieldReducer;