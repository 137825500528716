import { SET_TASK } from '../constants/newPlanogramsTypes';

const INITIAL_STATE = { selectTask: null};
 
const navigateTaskReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SET_TASK:
        return {
          ...state,
          selectTask: action.payload
        };
      default:
        return state;
    }
  };
 
export default navigateTaskReducer;