export const SELECTED_MP_DETAILS = 'SELECTED_DEPARTMENT_DETAILS';
export const SELECTED_CATEGORY_DETAILS = 'SELECTED_CATEGORY_DETAILS';
export const SELECTED_CATEGORY_RECT = 'SELECTED_CATEGORY_RECT';
export const SELECTED_SUBCATEGORY_DETAILS = 'SELECTED_SUBCATEGORY_DETAILS';
export const SELECTED_BRAND_DETAILS = 'SELECTED_BRAND_DETAILS';
export const MP_EDIT_ONE_CATEGORY_DETAILS = 'MP_EDIT_ONE_CATEGORY_DETAILS';

export const MP_CAT_DATA_CACHE = "MP_CAT_DATA_CACHE";
export const MP_CAT_NAVIGATE_CACHE = "MP_CAT_NAVIGATE_CACHE";

export const MP_SCAT_DATA_CACHE = "MP_SCAT_DATA_CACHE";
export const MP_SCAT_NAVIGATE_CACHE = "MP_SCAT_NAVIGATE_CACHE";

export const MP_BRAND_DATA_CACHE = "MP_BRAND_DATA_CACHE";
export const MP_BRAND_NAVIGATE_CACHE = "MP_BRAND_NAVIGATE_CACHE";

export const MP_LOADED_DEPARTMENTS = "MP_LOADED_DEPARTMENTS";
export const MP_STACKABLE_MARK_LIST = "MP_STACKABLE_MARK_LIST";
export const MP_VERSION_NAME = "MP_VERSION_NAME";
export const NEW_REFRESH = "NEW_REFRESH";
export const FILTER_DATES = "FILTER_DATES";

export const MP_DEPT_SEARCH = "MP_DEPT_SEARCH";
export const MP_EDIT_STACK_HISTORY = "MP_EDIT_STACK_HISTORY";
export const MP_FULL_SCREEN_SAVE_RES = "MP_FULL_SCREEN_SAVE_RES";
// export const MP_CACHE_CLIPBOARD_N_OTHERS = "MP_CACHE_CLIPBOARD_N_OTHERS";

export const AUI_MD_REDIRECT = "AUI_MD_REDIRECT";

export const NEW_PROD_COUNT_CAT = "NEW_PROD_COUNT_CAT"

export const NEW_PROD_COUNT_SUBCAT = "NEW_PROD_COUNT_SUB"

export const AUI_CONVERTED_DETAILS = "AUI_CONVERTED_DETAILS"
export const AFFECTED_SIMULATIONS_MODAL_DATA = "AFFECTED_SIMULATIONS_MODAL_DATA"
export const SIMULATION_PRODUCTSEARCH_DATA = "SIMULATION_PRODUCTSEARCH_DATA"
