import { UOM_WIDTH_LANGUAGE } from '../constants/themeTypes';

const INITIAL_STATE = { uomWidth: 'cm'};
 
const uomChangeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case UOM_WIDTH_LANGUAGE:
        return {
          ...state,
          uomWidth: action.payload
        };
      default:
        return state;
    }
};
 
export default uomChangeReducer;