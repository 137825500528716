import { SELECT_THEME } from '../constants/themeTypes';

const INITIAL_STATE = { selectTheme: true};
 
const themeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SELECT_THEME:
        return {
          ...state,
          selectTheme: action.payload
        };
      default:
        return state;
    }
  };
 
export default themeReducer;